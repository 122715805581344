import { CheckBox, CheckBoxOutlineBlank, Delete, Error } from "@mui/icons-material";
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Collapse, Container, Divider, Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { every } from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { FileViewer, UploadFile } from 'wcz-file';
import { LayoutContext, fetchDelete, fetchGet, fetchPut, newGuid } from "wcz-layout";
import BottomPanel from "../../components/checklist/BottomPanel";
import { TowerInput } from "../../components/checklist/TowerInput";
import CreateDefect from "../../components/defect/CreateDefect";
import UpdateDefect from "../../components/defect/UpdateDefect";
import Checklist from "../../models/Checklist";
import ChecklistTask from "../../models/ChecklistTask";
import ChecklistTaskScan from "../../models/ChecklistTaskScan";
import Defect from "../../models/Defect";
import { ChecklistStatus } from "../../models/enums/ChecklistStatus";
import { TaskType } from "../../models/enums/TaskType";
import { apiUrl } from "../../utils/BaseUrl";

const getChecklistWithoutChildren = (checklist: Checklist): Checklist => {
    return { ...checklist, tasks: [] };
};

const getChecklistTaskWithoutChildren = (task: ChecklistTask): ChecklistTask => {
    return { ...task, defects: [], scans: [] };
};

export default function UpdateChecklist() {
    const { id } = useParams();
    const [checklist, setChecklist] = useState<Checklist>({} as Checklist);
    const [addDefectTaskId, setAddDefectTaskId] = useState<string>("");
    const [editDefect, setEditDefect] = useState<Defect>({} as Defect);
    const { changeTitle, snackbar, user, i18n, t } = useContext(LayoutContext);

    const { refetch } = useQuery<Checklist>(["checklist", id], ({ signal }) => fetchGet(`${apiUrl}/v1/checklist/${id}`, signal), {
        onSuccess: data => { setChecklist(data); changeTitle(data.usn); }
    });

    const { mutate: updateChecklist } = useMutation((req: Checklist) => fetchPut(`${apiUrl}/v1/checklist/${req.id}`, getChecklistWithoutChildren(req)), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); }
    });

    const { mutate: updateTask } = useMutation((req: ChecklistTask) => fetchPut(`${apiUrl}/v1/checklist/task/${req.id}`, getChecklistTaskWithoutChildren(req)), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); }
    });

    const answerTask = (id: string, isAnswered: boolean) => {
        const updatedTasks: ChecklistTask[] = checklist.tasks.map(task => {
            if (task.id === id) {
                const updatedTask: ChecklistTask = { ...task, isAnswered: isAnswered, lastUpdated: moment().format(), lastUpdatedBy: `${user.name} (${user.id})` };
                updateTask(updatedTask);
                return updatedTask;
            } else {
                return task;
            }
        });

        const updatedChecklist: Checklist = {
            ...checklist,
            tasks: updatedTasks,
            lastUpdated: moment().format(),
            lastUpdatedBy: `${user.name} (${user.id})`
        };

        if (updatedChecklist.status === ChecklistStatus.NotStarted) {
            updatedChecklist.status = ChecklistStatus.InProgress;
            updateChecklist(updatedChecklist);
        }

        setChecklist(updatedChecklist);
    };

    const { mutate: updateTaskScan, isLoading: isUpdatingScan } = useMutation((req: ChecklistTaskScan) => fetchPut(`${apiUrl}/v1/checklist/task/${req.checklistTaskId}/scan`, req), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); }
    });

    const addScan = (taskId: string, value: string) => {
        if (isUpdatingScan) return;

        const updatedTasks: ChecklistTask[] = checklist.tasks.map(task => {
            if (task.id === taskId) {
                const updatedScan: ChecklistTaskScan = {
                    id: newGuid(),
                    value: value,
                    usn: checklist.usn,
                    created: moment().format(),
                    createdBy: `${user.name} (${user.id})`,
                    position: task.scans.length + 1,
                    checklistTaskId: task.id
                };
                updateTaskScan(updatedScan);

                const updatedTask: ChecklistTask = { ...task, isAnswered: updatedScan.position === 4, scans: [...task.scans, updatedScan] };
                return updatedTask;
            } else {
                return task;
            }
        });

        const updatedChecklist: Checklist = {
            ...checklist,
            tasks: updatedTasks,
            lastUpdated: moment().format(),
            lastUpdatedBy: `${user.name} (${user.id})`
        };

        if (updatedChecklist.status === ChecklistStatus.NotStarted) {
            updatedChecklist.status = ChecklistStatus.InProgress;
            updateChecklist(updatedChecklist);
        }

        setChecklist(updatedChecklist);
    };

    const { mutate: deleteScan, isLoading: isDeletingScan } = useMutation((id: string) => fetchDelete(`${apiUrl}/v1/checklist/scan/${id}`), {
        onError: (message: string) => { snackbar({ message: message, severity: "error" }); refetch(); }
    });

    const handleDeleteScan = (id?: string) => {
        if (isDeletingScan || !id) return;

        const updatedTasks: ChecklistTask[] = checklist.tasks.map(task => {
            if (task.scans.some(s => s.id === id)) {
                deleteScan(id);

                const updatedTask: ChecklistTask = { ...task, isAnswered: false, scans: task.scans.filter(s => s.id !== id) };
                return updatedTask;
            } else {
                return task;
            }
        });

        const updatedChecklist: Checklist = {
            ...checklist,
            tasks: updatedTasks,
            lastUpdated: moment().format(),
            lastUpdatedBy: `${user.name} (${user.id})`
        };

        setChecklist(updatedChecklist);
    };

    if (checklist.status === ChecklistStatus.Finished)
        return null;

    return (
        <Container>
            <Grid container sx={{ pb: 7 }}>
                <Grid item xs={12} sx={{ my: 1 }}>
                    <Typography><b>USN: </b> {checklist.usn}</Typography>
                    <Typography><b>Stage: </b> {checklist.stage}</Typography>
                </Grid>

                {checklist.tasks?.filter(task => task.stage === checklist.stage).map(task =>
                    <Grid item xs={12} key={task.id} sx={{ my: 0.5 }}>
                        <Card variant="outlined" sx={(theme) => ({
                            background: task.isHighlighted ? theme.palette.warning.main : undefined,
                            color: task.isHighlighted ? theme.palette.info.contrastText : undefined,
                        })}>
                            <CardActionArea onClick={() => answerTask(task.id as string, !task.isAnswered)} disabled={task.type !== TaskType.Confirm || !every(task.defects, { isFixed: true })}>
                                <CardHeader title={i18n.language === "cs" ? task.question : task.questionEn ?? task.question} avatar={
                                    <Box>
                                        {task.isAnswered ? <CheckBox color="success" /> : <CheckBoxOutlineBlank />}
                                    </Box>
                                } />
                            </CardActionArea>

                            <FileViewer subId={task.templateTaskId} disableActions />

                            {task.type === TaskType.Photo &&
                                <Box sx={{ p: 1 }}>
                                    <UploadFile subId={task.id as string} onSuccess={() => !task.isAnswered && answerTask(task.id as string, true)} />
                                    <FileViewer subId={task.id as string} onDelete={metas => !metas.length && answerTask(task.id as string, false)} />
                                </Box>
                            }

                            {task.type === TaskType.Scan &&
                                <CardContent sx={{ py: 0 }}>
                                    {!!task.scans.length &&
                                        <List dense disablePadding sx={{ mb: 2 }}>
                                            {task.scans.map((scan, index) =>
                                                <ListItem disablePadding key={scan.id}>
                                                    <Grid container direction="row" spacing={1} sx={{ alignItems: "center" }}>
                                                        <Grid item>
                                                            <Typography gutterBottom>Tower {index + 1}: <b>{scan.value}</b></Typography>
                                                        </Grid>
                                                        <Grid item sx={{ mb: 1 }}>
                                                            <IconButton size="small" color="error" onClick={() => handleDeleteScan(scan.id)}>
                                                                <Delete fontSize="small" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            )}
                                        </List>
                                    }

                                    {task.scans.length < 4 &&
                                        <TowerInput task={task} addScan={addScan} />
                                    }
                                </CardContent>
                            }

                            <CardActions disableSpacing>
                                <Button color="inherit" size="small" onClick={() => setAddDefectTaskId(task.id as string)} disabled={task.isAnswered}>{t("CreateDefect")}</Button>
                            </CardActions>

                            <Collapse in={!!task.defects?.length} timeout="auto" unmountOnExit sx={{ px: 2 }}>
                                <Divider />
                                <Grid container>
                                    {task.defects?.map(defect =>
                                        <Grid item xs={12} key={defect.id}>
                                            <CardActionArea onClick={() => setEditDefect(defect)}>
                                                <CardHeader title={defect.description} subheader={i18n.language === "cs" ? defect.category.nameCz : defect.category.name}
                                                    action={<Error color="error" fontSize="large" />} avatar={
                                                        <Box>
                                                            {defect.isFixed ? <CheckBox color="success" /> : <CheckBoxOutlineBlank />}
                                                        </Box>
                                                    } />
                                            </CardActionArea>
                                        </Grid>
                                    )}
                                </Grid>
                            </Collapse>
                        </Card>
                    </Grid>
                )}
            </Grid>

            <BottomPanel checklist={checklist} setChecklist={setChecklist} updateChecklist={updateChecklist} updateTask={updateTask} />
            <CreateDefect checklist={checklist} setChecklist={setChecklist} refetch={refetch} addDefectTaskId={addDefectTaskId} setAddDefectTaskId={setAddDefectTaskId} />
            <UpdateDefect checklist={checklist} setChecklist={setChecklist} refetch={refetch} editDefect={editDefect} setEditDefect={setEditDefect} />
        </Container>
    );
}