import { ArrowBack, ArrowForward, Done, Send } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { every } from "lodash";
import moment from "moment";
import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext, fetchDelete, fetchPost, hasRole } from "wcz-layout";
import Checklist from "../../models/Checklist";
import ChecklistTask from "../../models/ChecklistTask";
import { ChecklistStatus } from "../../models/enums/ChecklistStatus";
import { TaskType } from "../../models/enums/TaskType";
import { apiUrl } from "../../utils/BaseUrl";
import { adminRole } from "../../utils/userRoles";

interface BottomPanelProps {
    checklist: Checklist,
    setChecklist: (checklist: Checklist) => void,
    updateChecklist: (checklist: Checklist) => void,
    updateTask: (task: ChecklistTask) => void
}
export default function BottomPanel(props: BottomPanelProps) {
    const areAllTasksAnswered: boolean = useMemo(() => every(props.checklist.tasks?.filter(task => task.stage === props.checklist.stage), { isAnswered: true }), [props.checklist]);
    const navigate = useNavigate();
    const { snackbar, user, t } = useContext(LayoutContext);

    const { mutate: removeDefect } = useMutation((id: string) => fetchDelete(`${apiUrl}/v1/defect/${id}`));

    const { mutate: synchronizeWithTemplate } = useMutation((id: string) => fetchPost(`${apiUrl}/v1/checklist/${id}/syncWithTemplate`, null), {
        onSuccess: (data: Checklist) => props.setChecklist(data)
    });

    const handleOnPreviousStageClick = () => {
        if (!window.confirm("Decreasing the stage will unanswer all tasks in the current stage."))
            return;

        const updatedTasks: ChecklistTask[] = props.checklist.tasks.map(task => {
            if (task.stage === props.checklist.stage) {
                task.defects.forEach(defect => removeDefect(defect.id as string));
                const updatedTask = { ...task, isAnswered: (task.type === TaskType.Photo || task.type === TaskType.Scan) && task.isAnswered, defects: [], lastUpdated: undefined, lastUpdatedBy: undefined };
                props.updateTask(updatedTask);
                return updatedTask;
            } else {
                return task;
            }
        });

        const updatedChecklist: Checklist = { ...props.checklist, stage: props.checklist.stage - 1, tasks: updatedTasks, lastUpdated: moment().format(), lastUpdatedBy: `${user.name} (${user.id})` };
        props.setChecklist(updatedChecklist);
        props.updateChecklist(updatedChecklist);
        window.scrollTo(0, 0);

        if (updatedChecklist.stage === 4) {
            setTimeout(() => synchronizeWithTemplate(updatedChecklist.id as string), 1000);
        }
    };

    const handleOnNextStageClick = () => {
        const updatedChecklist: Checklist = { ...props.checklist, stage: props.checklist.stage + 1, lastUpdated: moment().format(), lastUpdatedBy: `${user.name} (${user.id})` };
        props.setChecklist(updatedChecklist);
        props.updateChecklist(updatedChecklist);
        window.scrollTo(0, 0);
    };

    const { mutate: sendToSfcs } = useMutation((req: Checklist) => fetchPost(`${apiUrl}/v1/checklist/sendToSfcs`, req), {
        onSuccess: () => handleOnNextStageClick(),
        onError: () => snackbar({ message: "Nelze poslat výsledky do sfcs, zkontroluj SFCS stage!", severity: 'error' })
    });

    const handleOnChecklistFinish = () => {
        const updatedChecklist: Checklist = { ...props.checklist, status: ChecklistStatus.Finished, lastUpdated: moment().format(), lastUpdatedBy: `${user.name} (${user.id})` };
        props.setChecklist(updatedChecklist);
        props.updateChecklist(updatedChecklist);
        navigate("/");
    };

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4}>
            <BottomNavigation showLabels>
                {(props.checklist.stage > 1 && hasRole(adminRole)) &&
                    <BottomNavigationAction label={"Stage " + (props.checklist.stage - 1)} icon={<ArrowBack />} onClick={handleOnPreviousStageClick} />
                }

                {(props.checklist.stage < 5 && areAllTasksAnswered) &&
                    <BottomNavigationAction label={"Stage " + (props.checklist.stage + 1)} icon={<ArrowForward />} onClick={() => handleOnNextStageClick()} />
                }

                {(props.checklist.stage === 3 || props.checklist.stage === 4) && areAllTasksAnswered &&
                    <BottomNavigationAction label={t("SendToSfcs")} icon={<Send />} onClick={() => sendToSfcs({ ...props.checklist, stage: props.checklist.stage + 1 })} />
                }

                {(props.checklist.stage === 5 && areAllTasksAnswered) &&
                    <BottomNavigationAction label={t("Finish")} icon={<Done />} onClick={handleOnChecklistFinish} />
                }
            </BottomNavigation>
        </Paper>
    );
}